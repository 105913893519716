export default [
    {
        path: '/c/:company_id/salechannel',
        name: 'salechannel',
        component: () => import('@/views/misc/setting/salechannel/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/salechannel/:salechannel_id',
        name: 'salechannel_create',
        component: () => import('@/views/misc/setting/salechannel/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/payment',
        name: 'payment',
        component: () => import('@/views/misc/setting/payment/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/payment/gbp',
        name: 'payment_gbp',
        component: () => import('@/views/misc/setting/payment/gbp.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/payment/beam',
        name: 'payment_beam',
        component: () => import('@/views/misc/setting/payment/beam.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/payment/:payment_id',
        name: 'payment_create',
        component: () => import('@/views/misc/setting/payment/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/delivery',
        name: 'delivery',
        component: () => import('@/views/misc/setting/delivery/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/delivery/shippop',
        name: 'delivery_shippop',
        component: () => import('@/views/misc/setting/delivery/shippop.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/delivery/:delivery_id',
        name: 'delivery_create',
        component: () => import('@/views/misc/setting/delivery/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/company',
        name: 'company_create',
        component: () => import('@/views/misc/setting/company/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/print',
        name: 'print_create',
        component: () => import('@/views/misc/setting/print/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/responserule',
        name: 'responserule',
        component: () => import('@/views/misc/setting/responserule/index.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
    {
        path: '/c/:company_id/responserule/:responserule_id',
        name: 'responserule_create',
        component: () => import('@/views/misc/setting/responserule/create.vue'),
        meta: {
            layout: 'content',      
            resource: 'Setting',
            action: 'read'
        },
    },
]